import {
  INotification,
  messagesAndNotificationsSelectors,
  settingsSelectors,
  State,
  TabDisplayInterface,
  UserType,
} from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessagesAndNotificationsTabDisplayService implements TabDisplayInterface {
  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(settingsSelectors.getUserType),
      map((userType) => userType === UserType.TEACHER),
    );
  }

  notificationCount(): Observable<number> {
    return this.store.pipe(
      select(messagesAndNotificationsSelectors.getNotifications),
      map((notifications: INotification[]) => notifications.filter(({ isRead }) => !isRead).length),
    );
  }
}
