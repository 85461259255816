import { settingsSelectors, State, TabDisplayInterface } from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { isStaff } from '@app/authentication/user.data';

@Injectable({
  providedIn: 'root',
})
export class GoalsTabDisplayService implements TabDisplayInterface {
  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(settingsSelectors.getUserType),
      map((userType) => isStaff(userType)),
    );
  }
}
