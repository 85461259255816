import { State, TabDisplayInterface, UserRole } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(authenticationSelectors.getUserRoles),
      map(
        (roles: UserRole[]) =>
          roles?.includes(UserRole.DISTRICT_ADMIN) || roles?.includes(UserRole.SCHOOL_ADMIN),
      ),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
